<template>
  <v-app>
    <SideBar v-model="drawer" />
    <AppBar @nav-collapse="toggleDrawer" />
    <v-main class="main-panel">
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
export default {
  components: {
    AppBar: () => import("@/views/layouts/partials/AppBar"),
    SideBar: () => import("@/views/layouts/partials/SideBar"),
  },
  data: () => ({
    drawer: null,
  }),
  methods: {
    toggleDrawer() {
      this.$set(this, "drawer", !this.drawer);
    },
  },
};
</script>

<style scoped>
.main-panel {
  /*background: #f6f6f6;*/
}
</style>
